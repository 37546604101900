.loginPageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
position: relative;
    height: 100%;
}

.loginFormContainer {
    width: 500px;
}
