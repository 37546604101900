#battleButton {
    box-shadow: inset 1px 0px 6px -1px #91b8b3;
    background: linear-gradient(to bottom, #080808 5%, #6c7c7c 100%);
    background-color: #080808;
    border-radius: 7px;
    border: 2px solid #566963;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 12px;
    text-decoration: none;
    text-shadow: 0px 3px 0px #2b665e;
}

#battleButton:hover {
    background: linear-gradient(to bottom, #6c7c7c 5%, #080808 100%);
    background-color: #6c7c7c;
}

#battleButton:active {
    position: relative;
    top: 1px;
}
